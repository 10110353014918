.ui--home {
  &-container {
  }

  &-section {
    .bar {
      @apply h-12 bg-theme-100;
    }

    .title {
      @apply mx-auto w-80 py-1 text-center uppercase ring-1 ring-theme;
    }

    .btn {
      &-more {
        @apply mx-auto w-36 rounded-none py-2 hover:underline;
      }
    }

    &.as-hero {
      @apply relative h-svh bg-gray-100;

      &::before {
        @apply absolute inset-0 z-10 bg-black/20;
        content: '';
      }

      img,
      video {
        @apply h-svh w-svw max-w-2k bg-transparent object-cover object-center;
      }

      .content {
        @apply relative grid h-full pt-24;
        grid-template-rows: 1fr auto;
      }
    }

    &.as-location {
      @apply relative grid gap-4 py-4 md:gap-8 md:py-16;

      .swiper {
        @apply pb-8;

        &-slide {
          &-prev,
          &-next {
            .swiper-card {
              @apply opacity-90;
            }
          }

          &-active {
            .swiper-card {
            }
          }
        }

        &-card {
          @apply transition duration-700 ease-in-out;
        }
      }
    }

    &.as-explore {
      @apply py-4 md:py-0;

      .card {
        &:hover {
          img {
            @apply scale-110;
          }
        }
      }
    }

    &.as-shool {
      @apply py-4 md:py-16;

      .swiper {
        @apply pb-8;

        &-slide {
          @apply bg-theme-200 sm:w-96;

          &-prev,
          &-next {
            .swiper-card {
              @apply opacity-90;
            }
          }

          &-active {
            .swiper-card {
            }
          }
        }

        &-card {
          @apply transition duration-700 ease-in-out;
        }
      }
    }

    &.as-product {
      .content {
        @apply mt-8 h-[640px] w-screen max-w-2k;
      }

      .swiper {
        &-slide {
          @apply w-full bg-theme-200;

          &-prev,
          &-next {
            .swiper-card {
            }
          }

          &-active {
            .swiper-card {
            }
          }
        }

        &-card {
          @apply relative h-[640px] bg-theme-200;
        }
      }
    }
  }
}
